import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from 'gatsby-plugin-image'

import '../styles/servicePage.scss'

import Seo from '../component/Seo'
import Layout from '../templates/Layout'
import Navigation from '../component/navigation/Navigation'
import Card from '../component/servicePage/card/Card'
import Footer from '../component/mainPage/callbackSection/CallbackSection'

function Outdoor() {
  const data = useStaticQuery(graphql`
  {
    allInteriorDataJson {
      nodes {
        cost
        id
        name
        time
        url
        image {
          childImageSharp {
            gatsbyImageData(
              quality: 85
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
      }
    }
    allMobileDataJson {
      nodes {
        name
        time
        cost
        id
        url
        image {
          childImageSharp {
            gatsbyImageData(
              quality: 85
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
      }
    }
    allOutdoorDataJson {
      nodes {
        id
        cost
        time
        name
        url
        image {
          childImageSharp {
            gatsbyImageData(
              quality: 85
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
      }
    }
  }
`)

  return (
    <div className="servicePage">
      <Seo title="Наружная реклама" />
      <Navigation />
      <Layout styleName="serviceContent">
        <h1 className="titleBlue">Наружная реклама</h1>
        <h2>Информационные вывески</h2>
        <div className="cardsList">
          {data.allOutdoorDataJson.nodes.map(outdoorInfo => {
            const { name, cost, time, image, id, url } = outdoorInfo;
            const imgSrc = getImage(image)

            return <Card key={id} name={name} cost={cost} time={time} imgSrc={imgSrc} url={url} />
          })}
        </div>
        <h2>Интерьерное оформление</h2>
        <div className="cardsList">
          {data.allMobileDataJson.nodes.map(mobileInfo => {
            const { name, cost, time, image, id, url } = mobileInfo;
            const imgSrc = getImage(image)

            return <Card key={id} name={name} cost={cost} time={time} imgSrc={imgSrc} url={url} />
          })}
        </div>
        <h2>Мобильные конструкции</h2>
        <div className="cardsList">
          {data.allInteriorDataJson.nodes.map(interiorInfo => {
            const { name, cost, time, image, id, url } = interiorInfo;
            const imgSrc = getImage(image)

            return <Card key={id} name={name} cost={cost} time={time} imgSrc={imgSrc} url={url} />
          })}
        </div>
      </Layout>
      <Footer />
    </div>
  )
}

export default Outdoor
